import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { WindowService } from '../../../commons/services/window.service';
import { ResponsiveService } from '../../../commons/services/responsive.service';

/**
 * Componente partners.
 */
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss',
})
export class PartnersComponent implements OnInit, AfterViewInit {
  /**
   * Variable para las imágenes.
   */
  images: string[] = [];
  /**
   * Contiene el listado de todas las imágenes.
   */
  allImages = [
    '/assets/images/partners/images/1.svg',
    '/assets/images/partners/images/2.svg',
    '/assets/images/partners/images/3.svg',
    '/assets/images/partners/images/4.svg',
    '/assets/images/partners/images/5.svg',
    '/assets/images/partners/images/6.svg',
    '/assets/images/partners/images/7.svg',
    '/assets/images/partners/images/8.svg',
    '/assets/images/partners/images/9.svg',
    '/assets/images/partners/images/10.svg',
    '/assets/images/partners/images/11.svg',
    '/assets/images/partners/images/12.svg',
    '/assets/images/partners/images/13.svg',
  ];
  /**
   * Repite la cantidad de imágenes.
   */
  repeatCount: number = 2;
  /**
   * Variable para la posición inicial.
   */
  private position = 0;
  /**
   * Ancho total de slider.
   */
  private totalWidth = 0;
  /**
   * Offset del slider.
   */
  offset: number = 200;
  /**
   * Controla el slider.
   */
  @ViewChild('itemsContainer') itemsContainer!: ElementRef;

  /**
   * Constructor
   * @param windowService
   * @param responsiveService
   */
  constructor(
    private windowService: WindowService,
    private responsiveService: ResponsiveService
  ) {}

  /**
   * Baraja las imágenes para que aparezcan en orden aleatorio
   */
  ngOnInit(): void {
    this.images = this.shuffleArray(this.allImages);
    this.populateItems();
  }

  /**
   * Empieza el slider
   */
  ngAfterViewInit() {
    this.startSlider(1);
  }

  /**
   * Evita el uso de las flechas para mover el slider.
   * @param event
   */
  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    const keysToPrevent = ['ArrowLeft', 'ArrowRight'];
    if (keysToPrevent.includes(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * Añade los items del scroll
   */
  populateItems() {
    this.images.push(...this.images);
    this.images.push(...this.images);
    // for (let i = 0; i < this.repeatCount; i++) {
    //   this.images.push(...this.images);
    // }
  }

  /**
   * Función para aleatorizar el orden de las imágenes
   * @param array
   * @returns
   */
  shuffleArray(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  /**
   * Avanza
   */
  next() {
    this.startSlider(1);
  }

  /**
   * Retrocede
   */
  prev() {
    this.startSlider(-1);
  }

  /**
   * Mueve el slider en la dirección indicada
   * @param direction Dirección en la que se mueve el slider
   */
  startSlider(direction: number) {
    if (this.windowService.isBrowser) {
      const container = this.itemsContainer.nativeElement as HTMLElement;
      let totalWidth = 0;
      const images = container.querySelectorAll('img');
      const loadedImages = Array.from(images) as HTMLImageElement[];
      let loadedCount = 0;

      loadedImages.forEach((img) => {
        img.onload = () => {
          loadedCount++;
          if (loadedCount === loadedImages.length) {
            totalWidth = 0;
            loadedImages.forEach((img) => {
              totalWidth += img.offsetWidth;
            });
            this.totalWidth = totalWidth;
            this.animateSlider(container, totalWidth, direction);
          }
        };
      });

      if (
        loadedImages.length === 0 ||
        loadedImages.every((img) => img.complete)
      ) {
        totalWidth = 0;
        loadedImages.forEach((img) => {
          totalWidth += img.offsetWidth;
        });
        this.totalWidth = totalWidth;
        this.animateSlider(container, totalWidth, direction);
      }
    }
  }

  /**
   * Función para animar el slider
   */
  animateSlider(container: HTMLElement, totalWidth: number, direction: number) {
    let position = this.position;

    if (this.responsiveService.isPC()) {
      this.offset = -700;
    } else if (this.responsiveService.isLandscape()) {
      this.offset = -300;
    } else if (this.responsiveService.isTablet()) {
      this.offset = -100;
    } else {
      this.offset = 200;
    }

    const animate = () => {
      position += direction;
      if (Math.abs(position) >= totalWidth + this.offset) {
        position = 0;
      } else if (position < 0) {
        position = totalWidth + this.offset;
      }
      container.style.transform = `translateX(-${position}px)`;
      this.position = position;
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  }
}
